import GettingStartedIcon from "@/components/assets/FAQ/question.svg"
import WatchingIcon from "@/components/assets/FAQ/watching.svg"
import AccountIcon from "@/components/assets/FAQ/account.svg"
import AdsIcon from "@/components/assets/FAQ/ads.svg"

import CommonlyQuestions from "./tabs/CommonlyAskedQuestions.vue"
import WhatDevice from "./tabs/WhatDevice.vue"
import InternetBandWidth from "./tabs/InternetBandWidth.vue"
import DoYouProvide from "./tabs/DoYouProvide.vue"
import DoYouHaveAdverts from "./tabs/DoYouHaveAdverts.vue"
import PayUsingCashApp from "./tabs/PayUsingCashApp.vue"
import HowManyDevices from "./tabs/HowManyDevices.vue"
import WhatHardware from "./tabs/WhatHardware.vue"
import CanIRequest from "./tabs/CanIRequest.vue"
import UploadSubtitles from "./tabs/UploadSubtitles.vue"
import ChangeTimezone from "./tabs/ChangeTimezone.vue";
import DoINeedVPN from "./tabs/DoINeedVPN.vue";
import WhyWeDontSupport from "./tabs/WhyWeDontSupport.vue";
import RenewMySubscription from "./tabs/RenewMySubscription.vue";
import ResendOrChange from "./tabs/ResendOrChange.vue";
import HowDoAccess from "./tabs/HowDoAccess.vue";
import Refund from "./tabs/Refund.vue";
import AppSometimesStop from "./tabs/AppSometimesStop.vue";
import LimitCode from "./tabs/LimitCode.vue";
import BadUsernameOrPassword from "./tabs/BadUsernameOrPassword.vue";
import Error401 from "./tabs/Error401.vue";
import NotMovies from "./tabs/NotMovies.vue";
import Chromecast from "./tabs/Chromecast.vue";
import Error403 from "./tabs/Error403.vue";
import Error404 from "./tabs/Error404.vue";
import PlayerType from "./tabs/PlayerType.vue"
import NotWorking from "./tabs/NotWorking.vue";
import GeneralTroubleshooting from "./tabs/GeneralTroubleshooting.vue";

export const faqData = [
    {
        id: "1",
        title: "faqTab.mainTitles.gettingStarted",
        icon: GettingStartedIcon,
        backgroundColor: "#FFCF2514",
        content: [
            {
                id: "1",
                title: "faqTab.gettingStarted.headers.questions",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.gettingStarted.subtexts.questions",
                content: CommonlyQuestions
            },
            {
                id: "2",
                title: "faqTab.gettingStarted.headers.devices",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.gettingStarted.subtexts.devices",
                content: WhatDevice
            },
            {
                id: "3",
                title: "faqTab.gettingStarted.headers.badwidth",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.gettingStarted.subtexts.badwidth",
                content: InternetBandWidth,
            },
            {
                id: "4",
                title: "faqTab.gettingStarted.headers.provide",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.gettingStarted.subtexts.provide",
                content: DoYouProvide
            },
            {
                id: "5",
                title: "faqTab.gettingStarted.headers.adverts",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.gettingStarted.subtexts.adverts",
                content: DoYouHaveAdverts
            },
            {
                id: "6",
                title: "faqTab.gettingStarted.headers.pay",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.gettingStarted.subtexts.pay",
                content: PayUsingCashApp
            },
        ],
    },
    {
        id: "2",
        title: "faqTab.mainTitles.watching",
        backgroundColor: "#14B43714",
        icon: WatchingIcon,
        content: [
            {
                id: "1",
                title: "faqTab.watching.headers.devicesAmount",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.watching.subtexts.devicesAmount",
                content: HowManyDevices
            },
            {
                id: "2",
                title: "faqTab.watching.headers.hardware",
                updateDate: "Tue, 22 Aug, 2023 at 9:44 PM",
                subtext: "faqTab.watching.subtexts.hardware",
                content: WhatHardware
            },
            {
                id: "3",
                title: "faqTab.watching.headers.request",
                updateDate: "Fri, 11 Aug, 2023 at 2:15 PM",
                subtext: "faqTab.watching.subtexts.request",
                content: CanIRequest
            },
            {
                id: "4",
                title: "faqTab.watching.headers.subtitles",
                updateDate: "Wed, 25 Mar, 2020 at 4:08 PM",
                subtext: "faqTab.watching.subtexts.subtitles",
                content: UploadSubtitles
            },
            {
                id: "5",
                title: "faqTab.watching.headers.timezone",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.watching.subtexts.timezone",
                content: ChangeTimezone
            },
            {
                id: "6",
                title: "faqTab.watching.headers.vpn",
                updateDate: "Fri, 20 May, 2022 at 2:29 AM",
                subtext: "faqTab.watching.subtexts.vpn",
                content: DoINeedVPN
            },
            {
                id: "7",
                title: "faqTab.watching.headers.supportUsers",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.watching.subtexts.supportUsers",
                content: WhyWeDontSupport
            },
        ],
    },
    {
        id: "3",
        title: "faqTab.mainTitles.accountBillings",
        backgroundColor: "#596AFF14",
        icon: AccountIcon,
        content: [
            {
                id: "1",
                title: "faqTab.account.headers.renewSubscriptions",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.account.subtexts.renewSubscriptions",
                content: RenewMySubscription
            },
            {
                id: "2",
                title: "faqTab.account.headers.password",
                updateDate: "Tue, 22 Aug, 2023 at 9:44 PM",
                subtext: "faqTab.account.subtexts.password",
                content: ResendOrChange
            },
            {
                id: "3",
                title: "faqTab.account.headers.accountStatus",
                updateDate: "Fri, 11 Aug, 2023 at 2:15 PM",
                subtext: "faqTab.account.subtexts.accountStatus",
                content: HowDoAccess
            },
            {
                id: "4",
                title: "faqTab.account.headers.refund",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.account.subtexts.refund",
                content: Refund
            },
        ],
    },
    {
        id: "4",
        title: "faqTab.mainTitles.troubleshootingCantWatch",
        backgroundColor: "#9F25FF14",
        icon: AdsIcon,
        content: [
            {
                id: "1",
                title: "faqTab.troubleshooting.headers.general",
                updateDate: "Sat, 1 Jan, 2022 at 8:11 PM",
                subtext: "faqTab.troubleshooting.subtexts.general",
                content: GeneralTroubleshooting
            },
            {
                id: "2",
                title: "faqTab.troubleshooting.headers.notWorking",
                updateDate: "Tue, 22 Aug, 2023 at 9:44 PM",
                subtext: "faqTab.troubleshooting.subtexts.notWorking",
                content: NotWorking
            },
            {
                id: "3",
                title: "faqTab.troubleshooting.headers.stopsOrKicks",
                updateDate: "Fri, 11 Aug, 2023 at 2:15 PM",
                subtext: "faqTab.troubleshooting.subtexts.stopsOrKicks",
                content: AppSometimesStop
            },
            {
                id: "4",
                title: "faqTab.troubleshooting.headers.limitCode",
                updateDate: "Wed, 25 Mar, 2020 at 4:08 PM",
                subtext: "faqTab.troubleshooting.subtexts.limitCode",
                content: LimitCode
            },
            {
                id: "5",
                title: "faqTab.troubleshooting.headers.badUsernameOrPassword",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.troubleshooting.subtexts.badUsernameOrPassword",
                content: BadUsernameOrPassword,
            },
            {
                id: "6",
                title: "faqTab.troubleshooting.headers.playerType",
                updateDate: "Fri, 20 May, 2022 at 2:29 AM",
                subtext: "faqTab.troubleshooting.subtexts.playerType",
                content: PlayerType
            },
            {
                id: "7",
                title: "faqTab.troubleshooting.headers.notMovies",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.troubleshooting.subtexts.notMovies",
                content: NotMovies
            },
            {
                id: "8",
                title: "faqTab.troubleshooting.headers.error401",
                updateDate: "Fri, 20 May, 2022 at 2:29 AM",
                subtext: "faqTab.troubleshooting.subtexts.error401",
                content: Error401
            },
            {
                id: "9",
                title: "faqTab.troubleshooting.headers.chromecast",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.troubleshooting.subtexts.chromecast",
                content: Chromecast
            },
            {
                id: "10",
                title: "faqTab.troubleshooting.headers.error403",
                updateDate: "Fri, 20 May, 2022 at 2:29 AM",
                subtext: "faqTab.troubleshooting.subtexts.error403",
                content: Error403
            },
            {
                id: "11",
                title: "faqTab.troubleshooting.headers.error404",
                updateDate: "Wed, 25 Mar, 2020 at 3:49 PM",
                subtext: "faqTab.troubleshooting.subtexts.error404",
                content: Error404
            },
        ],
    },
];